#informationContainer {
    display: flex;
    flex-direction: row;
}

#info-container-blue {
    background-color: #122F4D;
    padding: 20px 20px 2px;
    border-radius: 16px;
    max-width: 400px;
    color: white;
    margin-right: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow for depth */
}

#info-container-blue p {
    display: block;
    margin-bottom: 18px;
}

#video-container {
    border-radius: 16px;
    max-width: 500px;
    width: 100%;
}

#video-wrapper {
    position: relative;
    border-radius: 16px;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow for depth */
}

#video-wrapper iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 16px; /* Match the container's border-radius */
}

#informationContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px; /* Adds some space below the info container */
}

/* Styling for the form */
#evenement-form {
    background: #f2f2f2;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #122F4D;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
}

#evenement-form label {
display: block;
padding-bottom: 32px;
}

#evenement-form label input,
#evenement-form label textarea {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px;
    box-sizing: border-box; 
    display: block;
}

#evenement-form label textarea {
    width: 100%;
    height: 120px;
}

#evenement-form input[type="submit"] {
    background-color: #E87E51;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 24px;
}

#evenement-form input[type="submit"]:hover {
    opacity: 0.9;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    #informationContainer {
        flex-direction: column;
    }
    #info-container-blue, #video-container {
        max-width: 100%;
        margin-bottom: 20px;
    }
}
