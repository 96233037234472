#textareaContainer{
    height: 150px;
    display: inline-block;
    border: 1px solid var(--blue);
    padding: 2px;
    border-radius: 2px;
    margin-left: 10px;
}

.opmerking{
    height: 70px!important;
}

#commentDescription{
    margin: 0 0 5px 10px;
    padding-left: 0;
    font-size: 12px;
}

textarea {
    width: 506px;
    height: 100%;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 12px;
    border: none;
    border-radius: 2px;
    box-sizing: border-box;
    padding: 12px 20px;
    resize: none;
}

textarea:focus{
    outline: none;
}

textarea::-webkit-scrollbar {
    width: 8px;
}
  
textarea::-webkit-scrollbar-track {
    background: rgb(241, 241, 241);
    border-radius: 10px;
}

textarea::-webkit-scrollbar-thumb {
    background: var(--orange);
    border-radius: 10px;
}

textarea::-webkit-scrollbar-thumb:hover {
    background: rgb(231, 93, 0);
}

#documentDescription{
    font-weight: 500;
    margin: 20px 0 15px 10px;
    font-size: 14px;
}

#akkoordDiv{
    margin: 20px 0 0 10px;
}

#goedkeuren{
    border: 2px solid var(--blue);
    background-color: rgb(48, 52, 112);
    margin-right: 3px;
    border-radius: 8px 0 0 8px;
}

#afwijzen{
    border: 2px solid rgb(219, 88, 0);
    background-color: var(--orange);
    border-radius: 0 8px 8px 0;
}

#akkoordDiv button{
    outline: none;
    padding: 5px;
    cursor: pointer;
    color: white;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    width: 110px;
}

#akkoordDiv button:hover{
    opacity: 0.8
}

#downloadButton{
    margin: 20px 0 0;
    display: block;
    padding: 12px;
    background: var(--blue);
    border: 1px solid var(--blue);
    border-radius: 2px;
    line-height: 0px;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
}

#downloadButton:hover{
    background: var(--orange);
    border: 1px solid var(--orange);
}

#downloadButton .fa{
    margin-right: 4px;
}

.show {
    display: block!important;
}

.dropdown {
    position: relative;
    display: inline-block;
  }  

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    padding: 5px;
    width: 150%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 0;
    border: 1px solid var(--blue);
    border-radius: 3px;
    margin-left: -2px;
  }

  .dropdown-content p {
    color: black;
    padding: 6px 16px;
    text-decoration: none;
    display: inline-block;
    font-weight: 400;
  }

  #downloadDocument{
    background: var(--blue);
    color: white;
    padding: 4px 10px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    float: right;
    margin-top: 3px;
}

#downloadDocument:hover{
    background-color: var(--orange);
}

#documentInfo td{
    cursor: auto;
    font-weight: 400;
}

.expires-soon,
.expired,
.not-expiring{
    padding: 10px;
    margin: 10px;
    display: inline-flex;
}
.expires-soon{
    border: 1px solid #d14d00;
    color: #d14d00;
}
.expired{
    border: 1px solid var(--expired);
    color: var(--expired);
}
.not-expiring{
    border: 1px solid var(--blue);
    color: var(--blue);
}
.submit-disabled{
    opacity: 0.3;
    cursor: default!important;
}
.submit-disabled:hover{
    opacity: 0.3!important;
}
#imageUploadContainer>div{
    display: flex;
}