.mainContent{
    margin: 30px 80px 48px 310px;
    width: 100%;
}

.dashboardContainer{
    margin-top: 100px;
}

.mainContent h1{
    color: var(--blue);
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 24px;
}

.mainContent p{
    font-weight: 400;
    padding-left: 10px;
}

#mainSection{
    padding-bottom: 24px;
}

#tableLink{
    margin-top: 15px;
    font-weight: 400;
    display: inline-block;
}

#tableLinkClick{
    cursor: pointer;
    text-decoration: underline;
    color: var(--blue);
}

.news{
    display: flex;
    flex-direction: column;
}

#newsSection{
    display: inline-flex;
    flex-direction: column;
    overflow: hidden;
    margin: 4px 8px;
    max-width: 600px;
    box-shadow: 4px 3px 5px 0px #00000042;
}

.informatie{
    border: 1px solid var(--informatie);
}
.informatie-head,
.informatie-button{
    background: var(--informatie);
}
.waarschuwing{
    border: 1px solid var(--warning);
}
.waarschuwing-head,
.waarschuwing-button{
    background: var(--warning);
}
.update{
    border: 1px solid var(--update);
}
.update-head,
.update-button{
    background: var(--update);
}
.alert{
    border: 1px solid var(--alert);
}
.alert-head,
.alert-button{
    background: var(--alert);
}

.news-header{
    padding: 6px;
    display: flex;
    color: white;
    align-items: center;
}
.news-header h1{
    font-size: 14px!important;
    margin: 0;
    color: white;
}

.news-body{
    border-top: none;
    position: relative;
}

.news-text{
    padding: 12px 8px!important;
    max-height: 100%;
    box-sizing: border-box;
    white-space: pre-line;
}

.news-text > *{
    all: revert!important;
    padding-left: 0!important;
}
.news-text > ul,
.news-text > ol{
    list-style-position: inside!important;
}
.news-text > p{
    margin: 0!important;
    font-size: 13px!important;
}
.news-text > h1,
.news-text > h2,
.news-text > h3{
    margin: 0!important;
    font-weight: 400!important;
}

.news-button{
    text-decoration: none;
    font-family: "Roboto";
    text-align: center;
    padding: 8px 10px;
    margin: 8px;
    border-radius: 6px;
    display: inline-block;
    cursor: pointer;
    color: #fff;
    font-size: 13px;
    min-width: 100px;
}
.news-button:hover{
    opacity: 0.9;
}

.read-more{
    width: 100%;
    padding: 4px 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: white;
    cursor: pointer;
    position: relative;
    transition: box-shadow 0.3s ease-in-out;
}
.read-more p{
    font-size: 12px;
    opacity: 0.7;
    padding: 0!important;
    -webkit-user-select: none;      
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 3;
    pointer-events: none;
}
.read-more:hover p{
    opacity: 0.9;
}
.read-more-helper{
    width: 100%;
    position: absolute;
    height: 44px;
    left: 0;
    bottom: 0;
    z-index: 2;
}

.extended{
    box-shadow: none!important;
}

.update-readmore{
    background-color: var(--update);
    box-shadow: 0 5px 8px 12px var(--update);
}
.waarschuwing-readmore{
    background-color: var(--warning);
    box-shadow: 0 5px 8px 12px var(--warning);
}
.alert-readmore{
    background-color: var(--alert);
    box-shadow: 0 5px 8px 12px var(--alert);
}
.informatie-readmore{
    background-color: var(--informatie);
    box-shadow: 0 5px 8px 12px var(--informatie);
}

.update-readmore.extended > div{
    background-color: var(--update);
}
.waarschuwing-readmore.extended > div{
    background-color: var(--warning);
}
.alert-readmore.extended > div{
    background-color: var(--alert);
}
.informatie-readmore.extended > div{
    background-color: var(--informatie);
}