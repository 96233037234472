/* RESET CSS */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
    font-family: "Roboto";
	vertical-align: baseline;
}

:root{
    --orange: #e87e51;
    --blue: #122f4d;
    --blue-accent: #1E4472;
    --cyan: #009DC4;
    --expiring: #f38219;
    --expired: #D32F2F;

    --succes: #4CAF50;
    --informatie: #1976D2 ;
    --warning: #ff9e0d;
    --update: #7E57C2;
    --alert:  #E53935;
}

html, body{
    height: 100%;
    width: 100%;
}

body{
    display: flex;
}

article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}

ol, ul {
	list-style-type: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

input::-ms-reveal,
input::-ms-clear {
	display: none;
}

/* ------------------------------------------------------------------------------- */

.noselect {
    /* DISABLE TEXT HIGHLIGHTING */

    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

body::-webkit-scrollbar {
    width: 12px;
	height: 12px;
}
  
body::-webkit-scrollbar-track {
    background: rgb(241, 241, 241);
}

body::-webkit-scrollbar-thumb {
    background: var(--orange);
}

body::-webkit-scrollbar-thumb:hover {
    background: rgb(231, 93, 0);
}

body::-webkit-scrollbar-corner {
    background: rgb(241, 241, 241);
}

iframe{
    width: 100%;
    height: 500px;
}

#app{
	display: inline-flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    position: relative;
}

#container{
    width: 100%;
    height: 100%;
}

#innerContainer{
    display: inline-flex;
    flex-direction: row;
    width: 100%;
}

.mainContent p{
    font-size: 14px;
}

#separator{
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(33, 35, 76,1), rgba(255, 255, 255, 0));
    width: 100%;
    margin: 20px auto;
    box-sizing: border-box;
}

#contactLink{
    color: inherit;
    color:var(--blue);
    text-decoration: none;
    outline: none;
    font-size: 14px;
}

#contactLink:hover{
    text-decoration: underline!important;
}

#newTicket{
    font-family: "Roboto";
    margin: 10px;
    text-align: center;
    text-decoration: none;
    justify-content: space-around;
    display: inline-block;
    padding: 6px;
    background: var(--blue);
    border: 1px solid var(--blue);
    border-radius: 2px;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
}
#newTicket:hover{
    background: var(--orange);
    border: 1px solid var(--orange);
}

/* RESPONSIVE */
@media only screen and (max-width: 1000px){
    .mainContent{
        padding: 0!important;
        margin: 100px 0 24px 0!important;
    }
    h1{
        font-size: 18px!important;
    }
    p{
        font-size: 12px;
    }
    label{
        font-size: 13px!important;
    }
    #tableLinkClick{
        padding: 4px 8px;
        background: var(--blue);
        text-decoration: none!important;
        color: white!important;
        border-radius: 8px;
    }
    textarea {
        width: 100%!important;
        font-size: 13px!important;
    }
    #textareaContainer{
        width: 90%!important;
    }
    #documentDescription{
        font-size: 12px;
        margin: 20px 0 7px 10px!important;
    }

    #invalidInfo{
        color: white!important;
        background: var(--blue);
        padding: 6px 8px!important;
        border-radius: 5px;
        float: none!important;
        font-weight: 300!important;
        margin-left: 10px;
    }
    #invalidInfo:hover{
        text-decoration: none!important;
        background: var(--orange);
    }
    #invalidInfo i{
        display: none;
    }
    .checkbox{
        margin-left: 10px!important;
    }
    #downloadFiles{
        padding: 8px 10px 8px 0!important;
        font-size: 12px!important;
        width: 170px!important;
    }
    #downloadFiles p{
        font-size: 14px!important;
        line-height: 11px!important;
    }
    #changePassword{
        padding: 12px 0!important;
        border-radius: 5px!important;
        float: none!important;
        font-weight: 300!important;
        font-size: 12px!important;
        margin-left: 10px!important;
    }
    #akkoordDiv{
        margin: 20px 0 0 0!important;
        display: flex;
        justify-content: center;
    }
}