hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0));
  width: 80%;
}

.navbar{
  height: 100%;
  width: 260px;
  background-color: var(--blue);
  position: fixed;
  overflow: hidden;
  z-index:9999;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  box-shadow: -2px 0 8px 7px #00000042;
}

.navbar::-webkit-scrollbar {
  display: none;
}

#companyLogo{
  position: relative;
  background-color: white;
  width: 258px;
  padding: 10px 0;
  text-align: center;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
}

#companyLogo img{
  max-height: 75px;
  max-width: 210px;
  vertical-align: middle;
}

.navbarHeader p{
  padding-top: 25px;
  color: white;
  text-align: center;
  margin: 0 5px 20px;
  font-weight: 400;
}

.navbarOptions{
  margin: 16px 0;
}

.navbarOptions a {
  padding: 16px 0 16px 24px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
  color: white;
  display: block;
  position: relative;
  transition:  0.1s ease-out;
}

.navbarOptions a:hover,
.navbarOptions .selected {
  background-color: var(--orange);
}

.navToggleSubmenu, #openContactModal {
  padding: 16px 0 16px 24px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
  color: white;
  display: block;
  position: relative;
  transition:  0.1s ease-out;
  cursor: pointer;
}

.navToggleSubmenu:hover, #openContactModal:hover {
  background-color: var(--orange);
}

.navDropdown a:first-child {
  box-shadow: inset -2px 4px 9px -6px rgba(0,0,0,0.4);
}
.navDropdown a:last-child {
  box-shadow: inset -2px -4px 9px -6px rgba(0,0,0,0.4);
}

.navDropdown a, #openContactModal {
  padding-left: 36px;
  background-color: var(--blue-accent);
}

.iconSpacing{
  width: 35px; 
  display: inline-block;
}
.dropdownChevron{
  display: inline;
  float: right;
  width: 35px; 
}

.notification,
.notificationBlank{
  height: 10px;
  width: 10px;
  padding: 8px;
  border-radius: 50%;
  position: absolute;
  right: 9px;
  top: 13px;
  font-weight: 300;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.notificationLoading{
  position: absolute;
  right: 9px;
  top: 13px;
}

.notification{
  color: white;
  background-color: var(--cyan);
}

.notificationBlank{
  background-color: transparent;
}

.navbarLowerOptions{
  position: absolute;
  padding-top: 10px;
}

.navbarLowerOptions a,
.navbarLowerOptions p{
  padding: 12px 24px;
  text-decoration: none;
  font-size: 15px;
  font-weight: 100;
  color: white;
  display: block;
  cursor: pointer;
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
}

.navbarLowerOptions a:hover,
.navbarLowerOptions p:hover{
  text-decoration: underline;
}

#logo4proces{
background-color: white;
width: 220px;
height: 85px;
margin: 25px 5px 5px 5px;
text-align: center;
}

#logo4proces img{
  margin-top: 5px;
  max-height: 75px;
  max-width: 210px;
}

#topBarPhone{
  background-color: var(--blue);
  border-right: 1px solid var(--blue);
  display: none;
  width: 100%;
  height: 80px;
  position: fixed;
  color: white;
  justify-content: space-between;
  z-index:9999;
  box-shadow: -2px 0 8px 5px #00000042;
}

#topBarPhone i{
  width: 40px;
  float: right;
  cursor: pointer;
  display: inline;
  margin: auto 0;
  padding: 20px;
  font-size: 20px;
  text-align: center;
}

#topBarPhone p{
  margin-left: 10px;
  font-size: 17px;
}

#topBarPhone img{
  height: 40px;
  object-fit: contain;
  margin: 14px 5px 0;
  max-width: 100px;
  padding: 6px 6px;
  background-color: white;
  border-radius: 2px;
  box-shadow: 3px 1px 6px #0000004f;
}

#nameBox{
  margin: auto 0;
  display: inline;
}

#nameBox #subName{
  margin-top: 5px;
  font-size: 14px;
}

.notification p{
  font-size: 13px;
  line-height: 10px;
}

#companyBanner {
  /* width: 670px; */
  margin-left: 300px;
}

#companyBannerWrapper {
  width: 100%;
  display: flex;
  position: absolute;
  padding-top: 12px;
  background-color: var(--blue);
  border-radius: 0 0 170px 0;
}

/* RESPONSIVE */
@media only screen and (max-width: 1000px){
  #topBarPhone{
    display: flex!important;
  }
  .navbar {
    height: 100%;
    width: 100%;
    margin-top: 80px;
  }
  #companyLogo,
  #companyBanner,
  #companyBannerWrapper,
  #logo4proces{
    display: none
  }

  .navbar hr{
    display: none;
  }
  .navbarOptions{
    margin: 0!important;
  }
  .navbarOptions a{
    padding: 6px 24px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 300;
    line-height: 40px;
    height: 40px;
  }

  .navbarLowerOptions{
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 15px 0;
    border-top: 1px solid white;
  }
  .navbarHeader p{
    display: none;
  }
  .navbarHeader{
    display: flex;
  }
  .hideOnPhone{
    display: none;
  }
}


