#loginBackground{
    height: 100%;
    width:100%;
    font-family: "Roboto";
    position: absolute;
    top: 0;
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: center center; 
}

#loginhr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0));
}

#loginHelper{
    position: absolute;
    top: 50%;
    margin-top: -220px;
    left: 0;
    width: 100%;
}

#loginLoading{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}
#loginLoading p{
    color: white;
    margin-top: 14px;
    font-size: 14px;
}
  
#loginContainer,
#loginFooter{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 350px;
    width: 90%;
    background: var(--blue);
    border-radius: 8px;
    box-shadow: 3px 1px 6px #0000004f;
    border: 1px solid transparent;
}

#loginFooter{
    margin-top: 12px;
    padding: 8px;
    box-sizing: border-box;
    color: white;
    font-weight: 300;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
}
#loginFooter a{
    color: white;
    text-decoration: none;
}
#loginFooter a:hover{
    text-decoration: underline;
}

#loginContainer img{
    max-height: 100px;
    max-width: 200px;
    display: block;
    box-sizing: border-box;
    margin: 20px auto 0;
    box-shadow: 3px 1px 6px #0000004f;
    background: white;
    border-radius: 6px;
    padding: 4px;
}

#loginContainer h1{
    color: white;
    margin: 20px 0 15px;
    font-size: 22px;
    text-align: center;
    font-weight: 400;
    padding: 0;
}

#loginContainer hr{
    width: 80%;
    opacity: 60%;
    margin-bottom: 20px;
}

#loginContainer label{
    float: left;
    font-size: 14px;
    color: white;
    font-weight: 300;
    margin-left: 21px;
    margin-bottom: 12px;
}

#loginContainer input{
    display: block;
    margin: 0 auto 24px;
    width: 75%;
    border-radius: 2px;
    font-size: 13px;
    color: white;
    font-weight: 300;
    border: none;
    height: 20px;
    padding: 8px 16px;
    background: rgba(255, 255, 255, .15);
}

#loginContainer input:focus{
    outline: none;
    background: rgba(255, 255, 255, .25);
}

.iconMargin{
    margin: 0 8px 0 4px;
}

.passwordContainer{
    display: flex;
    flex-direction: row;
    width: 100%; 
}

.passwordContainer i{
    margin-top: 3px;
    position: absolute;
    right: 0;
    margin-right: 27px;
    color:rgba(21, 44, 65, .8);
    font-size: 18px;
    cursor: pointer;
    padding: 5px;
}

.passwordContainer i:hover{
    color:rgba(21, 44, 65, 1);
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(255, 255, 255, .3);
    opacity: 1; /* Firefox */
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgba(255, 255, 255, .3);
}
::-ms-input-placeholder { /* Microsoft Edge */
    color: rgba(255, 255, 255, .3);
}

#loginButton{
    margin: 0 auto 30px;
    display: block;
    height: 20px;
    padding: 16px;
    width: 60%;
    background: var(--orange);
    border: none;
    border-radius: 2px;
    line-height: 2px;
    cursor: pointer;
    color: #fff;
    transition: 0.5s ease;
}

#loginButton:hover{
    opacity: 0.8;
}

#loginErrorContainer{
    flex-direction: row;
    margin: 10px 24px 15px;
    color: var(--warning);
    cursor: default;
}

#loginErrorMessage{
    margin-left: 5px;
    font-weight: 300;
    font-size: 12px;
}