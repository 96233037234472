#loadingBackground{
    position: fixed;
    overflow: hidden;
    min-width: 100%;
    min-height: 100%;
    background-color: white;
    z-index: 100;
    top: 0;
}

.loadingDiv{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 90%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 200;
}

.loadingDiv img{
    max-width: 100%;
}

.loadingDiv h1{
    color:var(--blue);
    margin-top: 20px;
    font-size: 20px!important;
}