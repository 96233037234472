#empty{
    color: #afafaf;
}

#invalidInfo{
    color: var(--blue);
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
    display: inline-block;
    padding: 0;
    height: 20px;
    line-height: 20px;
}

#invalidInfo:hover{
    text-decoration: underline;
}

#changePassword{
    font-family: "Roboto";
    margin: 40px 0;
    width: 200px;
    text-align: center;
    display: block;
    padding: 16px 30px;
    background: var(--blue);
    border: 1px solid var(--blue);
    border-radius: 2px;
    line-height: 0px;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
}

#changePassword:hover{
    background: var(--orange);
    border: 1px solid var(--orange);
}

label{
    font-size: 12px;
    color: var(--blue);
    font-weight: 500;
    line-height: 20px;
    height: 16px;
    display: inline-block;
}

.errorContainer{
    display: flex;
    flex-direction: row;
    margin: -10px 24px 15px;
    font-weight: 400;
    color: rgb(201, 0, 0);
    
    opacity: 0;
    cursor: default;
}

#errorMessage{
    margin-left: 5px;
    font-size: 14px;
    height: 30px;
    color: rgb(201, 0, 0);
}