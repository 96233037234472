.submit-disabled {
    opacity: 0.3;
    cursor: default !important;
}

.images-box {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.images-box>section {
    height: 120px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: var(--blue);
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}

.images-box>section:first-child {
    margin-right: 10px;
}