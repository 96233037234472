table{
    border: none;
    max-width: 100%;
}

thead th{
    padding-bottom: 20px;
    font-weight: 500;
    font-size: 14px;
}

tbody td{
    padding: 10px 0;
    font-weight: 300;
    cursor: pointer;
    max-width: 200px;
    font-size: 12px;
}

h1{
    padding-left: 10px;
}

tbody td, 
thead th{
    text-align: left;
    padding-right: 30px;
    padding-left: 10px;
    word-wrap: break-word;
    vertical-align: top;
    max-width: 100%;
}

.Facturen .unreadRecord td:first-child{
    border-left: none!important;
}

.unreadRecord,
.unreadRecordDashboard{
    border-left: 3px solid var(--cyan)!important;
}

#record:hover{
    background: #f3f3f3;
    border-left: 3px solid var(--blue);
}

#record{
    border-left: 3px solid transparent;
}

.unreadRecord td,
.unreadRecordDashboard td,
.soonExpiredRecord td,
.soonExpiredRecordDashboard td{
    font-weight: 500!important;
}

.expiredRecordDashboard:hover,
.expiredRecord:hover{
    background: #ffc8c8!important;
    border-left: 3px solid var(--expired)!important;
}
.soonExpiredRecordDashboard:hover,
.soonExpiredRecord:hover{
    background: #ffe0a7!important;
    border-left: 3px solid var(--expiring)!important;
}
.expiredRecord,
.expiredRecordDashboard{
    background: #ffe2e2!important;
    border-left: 3px solid var(--expired)!important;
}
.soonExpiredRecord,
.soonExpiredRecordDashboard{
    background: #ffeece!important;
    border-left: 3px solid var(--expiring)!important;
}

.record_Gelezen,
.record_Akkoord{
    text-align: center;
}

#center{
    text-align: center;
}

#dataTableOrganisation, 
#dataTablePersonal{
    display: inline-block;
    margin-bottom: 30px;
}

#dataTableOrganisation thead th, 
#dataTablePersonal thead th,
#dataTableOrganisation tbody td, 
#dataTablePersonal tbody td{
    max-width: max-content;
    cursor: auto;
}

#dataTableOrganisation tbody td, 
#dataTablePersonal tbody td{
    font-weight: 400;
}

.record_PDF{
width: 60px;
}

.record_PDF div{
    position: absolute;
    color: white;
    background-color:var(--blue);
    border-radius: 4px;
    padding: 4px 12px;
    margin-top: -6px;
}

.record_PDF p{
    padding: 0!important;
}

.record_PDF div:hover{
    background-color: var(--orange);
}

.record_Omschrijving{
    max-width: 300px;
}

.record_Bedrag{
    text-align: right!important;
    white-space: nowrap;
}

#informationContainer{
    display: flex;
}

.informationSeparator{
    display: none;
}

/* RESPONSIVE */
@media only screen and (max-width: 1000px){
    table{
        margin-left: 4px;
    }
    tbody td{
        font-size: 12px;
        padding-right: 15px!important;
    }
    thead th{
        font-size: 14px;
        padding-right: 15px!important;
    }

    .table_Akkoord,
    .record_Akkoord,
    .table_Uren,
    .record_Uren,
    .table_Week,
    .record_Week,
    .table_Gelezen,
    .record_Gelezen,
    .table_Omschrijving,
    .record_Omschrijving,
    .table_Contactpersoon,
    .record_Contactpersoon,
    .table_Openstaand,
    .record_Openstaand,
    .table_Betaald,
    .record_Betaald,
    .record_PDF p{
        display: none;
    }

    .record_PDF{
        width: 30px;
    }

    #dataTableOrganisation thead th,
    #dataTableOrganisation tbody td,
    #dataTablePersonal thead th,
    #dataTablePersonal tbody td{
        max-width: 100%;
        padding-right: 20px!important;
    }

    #noRecords{
        text-align: center;
    }

    #dataTableOrganisation{
        margin-bottom: 0;
    }

    #informationContainer{
        flex-direction: column;
    }

    .informationSeparator{
        display: block;
        margin-bottom: 30px!important;
    }
}

@media only screen and (min-width: 1000px){
    .record_PDF i{
        display: none;
    }
}