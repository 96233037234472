.modal-content {
    background-color: #fefefe;
    margin: auto;
    border-radius: 2px; 
    width: 440px;
}

.react-responsive-modal-closeButton{
    display: none!important;
}

.react-responsive-modal-modal{
    padding: 30px;
    margin-top: 10%;
    box-sizing: border-box;
}

#modalContainer{
    height: 100%;
    width: 100%;
}

.ReactModal__Overlay{
  background-color: rgba(0, 0, 0, 0.6)!important;  
}

.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    padding-left: 20px;
    margin-top: -10px;
    padding: 5px;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.modal-content h1{
    color: var(--blue);
    font-size: 16px;
    font-weight: 500;
}

.modal-content p{
    color: var(--blue);
    font-size: 14px;
    font-weight: 400;
}

.modal-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#subtitle{
    margin: 20px 0;
    padding-left: 10px;
}

#modal_hr,
#download_hr {
    border-top: 1px solid var(--blue);
    width: 100%;
}

#download_hr{
    float: left;
    margin-top: 0;
}

#modal-information{
    width: 75%;
}

#modal-information,
#modal-img{
    display: inline-block;
}

#modal-information ul{
    text-decoration: none;
    color: var(--blue);
    font-weight: 400;
}

#modal-information ul li{
    padding: 10px;
}

#modal-information i{
    margin-right: 10px;
}

#modal-img img{
    height: 80px;
    width: 80px;
}

#closeContactModal,
#savePassword,
#cancelPassword{
    font-family: "Roboto";
    margin: 30px auto 0;
    display: block;
    padding: 16px 40px;
    background: var(--blue);
    border: 1px solid var(--blue);
    border-radius: 2px;
    line-height: 0px;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    outline: none;
}

#savePassword,
#cancelPassword{
    display: inline;
    margin: auto;
    width: 165px;
    padding: 16px 0;
}

#savePassword:disabled{
    background-color: gray;
    cursor: auto;
    border: 1px solid #6d6d6d;
}

#centerPasswordButtons{
    margin-top: 20px;
    margin-left: -20px;
    width: 110%;
    display: flex;
}

#cancelPassword{
    border: 1px solid var(--blue);
    background: white;
    color: var(--blue);
}

#cancelPassword:hover{
    font-weight: 500;
}

#closeContactModal:hover,
#savePassword:enabled:hover{
    background: var(--orange);
    border: 1px solid var(--orange);
}

#modal-locationcontainer{
    position: relative;
}

#modal-locationcontainer i::before{
    display: inline-block;
    position: absolute;
    top: 0;
    left: 4px;
}

#modal-locationcontainer p{
    margin-left: 12px;
}

#modal-locationcontainer:hover p{
    text-decoration: underline;
}

#location{
    display: inline-block;
    color: var(--blue);
    font-size: 100%;
    font-weight: 400;
}

#passwordModal .modal-content{
    width: 330px;
}

#passwordModal h1{
    margin-bottom: 20px;
}

#passwordModal label{
    font-size: 14px;
    color: var(--blue);
    font-weight: 400;
    margin: 0 0 20px 0;
}

#passwordMessage{
    font-size: 15px;
    color: var(--blue);
    font-weight: 400;
    margin: 0 0 12px 16px;
    list-style-type: circle;
}

#passwordMessage li{
    margin-bottom: 8px;
    list-style-type: none!important;
}

#passwordMessage p{
    font-size: 15px;
    font-weight: 400;
}

#passwordTip{
    padding: 3px;
    background-color: var(--blue);
    color: white;
    border-radius: 4px;
    height: 20px;
    font-weight: 500!important;
}

#passwordMessage i{
    margin-right: 4px;
    font-size: 18px;
}

#passwordMessage #first{
    margin-bottom: 20px;
}

#modal-information input{
    outline: none;
    display: block;
    margin: 0 auto;
    width: 100%;
    border-radius: 2px;
    font-size: 14px;
    color: var(--blue);
    font-weight: 400;
    border: 1px solid var(--blue);
    height: 20px;
    padding: 8px 16px;
}

#oldPassword{
    margin-bottom: 20px!important;
}

#newPassword{
    margin-bottom: 8px!important;
}

#documentDescription{
    font-weight: 400;
    margin-bottom: 10px
}

#downloadFiles{
    font-family: "Roboto";
    margin: 10px;
    width: 200px;
    text-align: center;
    justify-content: space-around;
    display: flex;
    padding: 6px;
    background: var(--blue);
    border: 1px solid var(--blue);
    border-radius: 2px;
    line-height: 0px;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
}

#downloadFiles p{
    font-size: 14px;
    line-height: 15px;
}

#downloadFiles:hover{
    background: var(--orange);
    border: 1px solid var(--orange);
}

.download-modal-body{
    width: 100%!important;
    margin: 0!important;
    max-height:100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.download-modal-body::-webkit-scrollbar {
    width: 8px;
}
  
.download-modal-body::-webkit-scrollbar-track {
    background: rgb(241, 241, 241);
    border-radius: 10px;
}

.download-modal-body::-webkit-scrollbar-thumb {
    background: var(--orange);
    border-radius: 10px;
}

.download-modal-body::-webkit-scrollbar-thumb:hover {
    background: rgb(231, 93, 0);
}

#downloadDiv{
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin: 8px;
}

#downloadLink{
    display: inline;
    text-decoration: none;
    max-width: 65%;
    outline: none;
    color: var(--blue);
    padding: 4px;
    word-wrap:break-word;
    margin-right: 10px;
    font-size: 14px;
}
#downloadDiv button{
    background: var(--blue);
    display: inline;
    float: right;
    color: white;
    padding: 4px 10px;
    border-radius: 2px;
    outline: none;
    border: none;
    height: 28px;
    margin: auto 0;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}

#downloadDiv button:hover{
    background-color: var(--orange);
}

#downloadDiv:hover{
    box-shadow: -2px 0px 0px var(--blue);
    font-weight: 500;
}

.downloadDisabled{
    display: none!important;
}

/* RESPONSIVE */
@media only screen and (max-width: 1000px){
    .modal-content {
        width: 100%!important;
        height: 100%!important
    }

    #downloadFiles{
        margin: 20px 12px!important;
    } 

    .react-responsive-modal-modal{
        margin: 81px 0 0!important;
        width:100%!important;
        max-height: 80%;
        max-width:100%!important;
    }

    #modal-img img{
        width: 60px;
        height: 60px;
    }
}

/* RESPONSIVE */
@media only screen and (max-width: 500px){
    #downloadLink{
        width: 50%!important;
    }
    #download_hr{
        width: 60%!important;
    }
}