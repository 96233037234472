#notFoundBackground{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 9999;
}

.notFoundDiv{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 90%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.notFoundDiv img{
    max-width: 100%;
}

.notFoundDiv h1{
    color:var(--blue);
    margin-top: 30px;
    font-size: 18px!important;
    font-weight: 500;
}

#notFoundButton{
    font-family: "Roboto";
    margin: 40px 0;
    text-align: center;
    padding: 16px 30px;
    background: var(--blue);
    border: 1px solid var(--blue);
    border-radius: 2px;
    line-height: 0px;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
}

#notFoundButton:hover{
    background: var(--orange);
    border: 1px solid var(--orange);
}