#form-file-upload {
  height: 12rem;
  width: 22rem;
  max-width: 100%;
  text-align: center;
  position: relative;
  margin: 8px 0;
}

#image-preview {
  max-height: 12rem;
  max-width: 22rem;
  object-fit: contain;
  border: 1px solid var(--blue);
  border-radius: 1rem;
  margin: 8px 0;
}

#preview-container {
  position: relative;
}

#preview-container button {
  position: absolute;
  left: 10px;
  bottom: 20px;
  border: none;
  border-radius: 50%;
  background-color: #fa1a1ad0;
  color: #fff;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#preview-container button:hover {
  opacity: 0.9;
}

#preview-container button i {
  font-size: 16px;
}

.input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: var(--blue);
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  background-color: var(--blue);
  font-size: 14px;
  color: white;
  border: none;
  border-radius: 6px;
  margin-top: 4px;
}

.upload-button:hover {
  opacity: 0.9;
}

.ticket-submit-button{
  display: block;
  height: 20px;
  padding: 16px 80px;
  background: rgba(21, 44, 65, 1);
  border: none;
  border-radius: 2px;
  line-height: 2px;
  cursor: pointer;
  color: #fff;
}

.ticket-submit-button:hover{
  opacity: 0.9;
}

#file-name{
  position: absolute;
  top: 20px;
  width: 170px;
}

/* RESPONSIVE */
@media only screen and (max-width: 450px){
  .ticket-submit-button{
    margin: 16px auto!important;
    width: 100%;
  }
}

.drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}